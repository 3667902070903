<template>
    <div class="mLogin">
    <van-nav-bar>
        <template #right>
        <van-icon name="cross" size="20" color="#000" @click="$router.back()"  />
        </template>
    </van-nav-bar>
    <div class="login-content">
        <div class="mLogin-body">
            <div class="mLogin-form">
                <div v-if="active === 'verify_code'" class="verify-code">
                    <van-field v-model="formData.phone" label-width="70px" center clearable   
                        placeholder="请输入手机号" @input="handlePhoneOrCodeOrPtd('phone')">
                        <template #label>
                            <div class="label-text" @click="toPrefixPhone">{{ seletePrefix || defaultPrefix }} <span>▼ </span></div>
                        </template>
                          
                        <template #extra>
                            <div v-if="isShowDowm" :class="['send-code',formData.phone.length===11?'active-code':'']" :disabled="isDisableCode" @click="sendCode">获取验证码</div>
                            <van-count-down v-else :time="time" format="重新获取(ss)" @finish="isShowDowm=true" />
                        </template>
                    </van-field>
                    <van-field v-model="formData.verify_code" label-width="70px" center clearable   label="验证码"
                        placeholder="请输入四位验证码" @input="handlePhoneOrCodeOrPtd('code')">
                        <template #extra v-if="formData.verify_code.length===4">
                            <van-icon name="success" color="#1989fa" size="16" />
                      </template>
                    </van-field>
                </div>
                <template v-if="active === 'account_password'">

                    <van-field v-model="formData.username" label-width="50px" center clearable label="账号"
                        placeholder="请输入用户名">
                    </van-field>
                    <van-field v-model="formData.password" label-width="50px" center clearable label="密码"
                        placeholder="请输入登录密码" type="password" @input="handlePhoneOrCodeOrPtd('ptd')">
                        <template #extra>
                            <div class="forget-ptd">忘记密码?</div>
                        </template>
                    </van-field>

                </template>
                <div class="protocol-policy">
                    <van-checkbox v-model="agreeChecked" icon-size="12px">阅读并同意 <a href="#"
                            style="color:#1989fa">服务协议、隐私政策</a></van-checkbox>
                </div>
                <div class="mLogin-btn">
                    <van-button type="info" block round :disabled="btnIsDisabled" @click="handleLogin">登录</van-button>
                </div>
            </div>
            <div class="mLogin-tab">
                <div class="mLogin-email" @click="handleChangeLogin('verify_code')">手机验证码登录</div>
                <div class="mLogin-email" @click="handleChangeLogin('account_password')">账号密码登录</div>
            </div>
        </div>
        <!-- 协议弹出 -->
        <van-action-sheet v-model="showagree" title="服务协议及隐私政策" lock-scroll lazy-render>
            <div class="content">阅读并同意 <a style="color:#1989fa">服务协议、隐私政策</a></div>
            <div class="btn-bottom">
                <van-button type="default" round @click="showagree = false">不同意</van-button>
                <van-button type="info" round @click="handleAgree">同意并继续</van-button>
            </div>
        </van-action-sheet>
        <!-- 发送验证码通知提示-->
        <van-notify v-model="showCodeNotify" type="success" class="showNotify">
                <van-icon name="bell" style="margin-right: 4px;" />
                <span>已发送验证码</span>
        </van-notify>
        <!-- 登陆成功提示 -->
         <van-notify v-model="showSuccess" type="success" class="showNotify">
                <van-icon name="bell" style="margin-right: 4px;" />
                <span>登录成功</span>
        </van-notify>
    </div>
</div>
</template>

<script>
export default {
    name: 'mLogin',

    data() {
        return {
            agreeChecked: false,
            formData: {
                phone: '',
                username: '',
                password: '',
                verify_code: ''
            },
            defaultPrefix:'+86',
            active: 'verify_code',
            btnIsDisabled: true,
            showagree: false,
            valid: false,
            isDisableCode:true,
            isShowDowm:true,
            time:30000,
            showCodeNotify:false,
            showSuccess:false,
        };
    },
    computed:{
       seletePrefix()
       {
        return this.$store.state.seletePrefix;
       }
    },
    watch:{
    },
    mounted() {
      
    },
    methods: {
        // 表单验证
        handleValidate() {
            if (this.active === 'account_password') {
                this.valid = this.formData.username;
            } else {
                this.valid = this.formData.phone && this.formData.verifyCode;
            }
        },
        // 登录接口
        async handleLoginApi() {
            // 表单验证
            this.handleValidate();
            //短信登录
            if (this.active == 'verify_code') {
                try {
                } catch (error) {
                    console.log(error);
                }

            }
            //账号密码登录
            else {
                try {
                    let res = await this.api.postFormAPISM({ username: this.formData.username, password: this.formData.password }, '/user/login');
                    console.log(res);
                    // 用户名不存在
                    if (!this.valid) {
                        return this.$dialog({
                            message: res.data.data.msg,
                            confirmButtonColor:'#1989fa'
                        }).then(() => {
                        });
                    }
                    // 用户名存在
                    else{
                        if(res.data.data.success===false)
                        {
                            return this.$dialog({
                            message: res.data.data.msg,
                            confirmButtonColor:'#1989fa'
                        }).then(() => {
                            // on close
                        });
                        }
                    }
                    if(res.data.data.success=== true && res.status === 200)
                    {
                    localStorage.setItem('token', res.data.data.token);
                    this.$store.commit('setToken', res.data.data.token);
                    this.showSuccess = true;
                    setTimeout(() => {
                    this.showSuccess = false;
              }, 1000);
                         this.$router.push('/tool');
                    }
                } catch (error) {
                    console.log(error);
                }

            }
        },
       async handleLogin() {
            if (this.agreeChecked === false) {
                this.showagree = true;
            }
            else {
                // 调用登录接口
              await  this.handleLoginApi();
            //   清空数据
              this.formData={
                phone: '',
                username: '',
                password: '',
                verify_code: ''
            };
            this.showSuccess = false;
            this.agreeChecked = false;
            }
        },
        // 获取验证码
        sendCode()
        {
            if(this.formData.phone.length !==11)
            {
                this.isDisableCode=true;
            }
            else{
                this.isDisableCode=false;
                this.showCodeNotify=true;
                setTimeout(() => {
             this.showCodeNotify = false;
              }, 2000);
                // 展示倒计时
                this.isShowDowm= false;
            }
         
        },
        toPrefixPhone()
        {
           this.$router.push('/prefixPhone');
            this.defaultPrefix='';
           
        },
        // 同意并继续事件
       async handleAgree() {
            this.agreeChecked = true;
            this.showagree = false;
           await  this.handleLoginApi();
        //    清空数据
           //   清空数据
           this.formData={
                phone: '',
                username: '',
                password: '',
                verify_code: ''
            };
            this.showSuccess = false;
            this.agreeChecked = false;

        },
        handlePhoneOrCodeOrPtd(type)
        {
             if(type==='phone')
             {
               this.formData.phone && this.formData.verify_code >=4 ? this.btnIsDisabled=false: this.btnIsDisabled=true
             }
             else if(type==='code')
             {
                this.formData.phone && this.formData.verify_code >=4 ? this.btnIsDisabled=false: this.btnIsDisabled=true
             }
             else{
                if (this.formData.password.length >= 5) {
                this.btnIsDisabled = false;
                  }
              else {
                this.btnIsDisabled = true;
            }
             }
        },
        // 切换登录方式
        handleChangeLogin(active) {
            if (active !== this.active) {
                this.active = active;
                // 表单数据清空
            this.formData={
                phone: '',
                username: '',
                password: '',
                verify_code: ''
            }
            this.agreeChecked=false;
            this.btnIsDisabled=true;
            }
            
        }
    },
};
</script>

<style lang="less" scoped>
.login-content {
    padding: 0 15px;

    &>.mLogin-body {
        &>.mLogin-form {
            margin-top: 150px;
            
            &>.verify-code {
           .label-text{
            color: #000;
            & > span{
                font-size: 10px;
                color: #000;
            }
           }
            .send-code{
                color:#ccc;
                
            }
            .van-field__right-icon{
                color:#1989fa;
            }
            .active-code{
                color:#1989fa;
          }
          .forget-ptd{
            font-size: 14px;
          }
            .van-button--small {
                border: 0;
            }
        }
            &>.protocol-policy {
                padding: 15px;
                font-size: 12px;
                // & > .van-radio__label > .service-policy
                // {
                //     color: red;
                // }
            }

            &>.mLogin-btn {
                margin: 0 10px;
            }
        }

        &>.mLogin-tab {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            padding: 0 10px;
        }
    }

    .content {
        padding: 16px 16px 20px;
        text-align: center;
        font-size: 12px;
    }

    .btn-bottom {
        display: flex;
        justify-content: space-around;
        padding: 10px 0 20px;

        &>.van-button--normal {
            width: 40%;
        }

        &>.van-button--default {
            background-color: #ebedf0;
            color: #1989fa;
        }
    }
    .showNotify {
    left: 35%;
    top:10%;
    width: 40%;
    border-radius: 60px;
    
}
}

</style>